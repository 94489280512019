<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.create_note") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="createNote"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const user = computed(() => store.state.authentication.user);

    const formElements = ref([
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "textarea",
        id: "text",
        label: "text",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "text",
      },
      {
        class: "col-span-12 text-right",
        element: "button",
        id: "submit-button",
        label: "save",
        textVariant: company.value.primaryText,
        type: "submit",
        variant: company.value.primary,
      },
    ]);

    const createNote = (formData) => {
      const data = {
        createdAt: new Date().toISOString().split("T")[0],
        text: formData.text,
        userId: user.value.id,
      };
      store.dispatch("task/createNote", { data, taskId: route.params.taskId });
    };

    return {
      formElements,
      t,
      createNote,
    };
  },
};
</script>
